<template>
  <v-container>
    <v-row class="text-center">
      <!--      <v-col cols="12">
              <v-img
                  :src="require('../assets/Pozzy_Banner.png')"
                  class="my-3"
                  contain
                  height="200"
              />
            </v-col>-->
      <v-col
          class="mb-5"
          cols="12"
      >
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Meet the Pozzyvibes Team
          </h1>
        </v-col>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row dense>
            <v-col
                v-for="person in team"
                :key="person.name"
                :cols="auto"
            >
              <v-card
                  :loading="loading"
                  class="mx-auto my-12"
                  max-width="400"
              >
                <v-img
                    :src="person.src"
                    class="white--text align-end"
                    max-height="400"
                    max-width="400"
                >
                </v-img>
                <v-card-title v-html="person.name"></v-card-title>

                <v-card-text>
                  <div v-html=person.title></div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PozzyTeam',
  data: () => ({
    team: [
      {
        name: 'Tiffany Rosengrant',
        title: '<p>CEO & Mobile DJ</p>',
        src: require("../assets/team/Tiffany-Headshot.png")
      },
      {
        name: 'Bentley',
        title: 'Job Title TBD',
        src: require("../assets/team/Bentley-Headshot.png")
      },
      {
        name: 'Aaron Bornhoft',
        title: 'Event Assistant',
        src: require("../assets/team/Aaron-Headshot-2.png")
      },
      {
        name: 'Adam Holt',
        title: 'Event Assistant',
        src: require("../assets/team/Adam-Headshot.png")
      },
      {
        name: 'Danielle Lark',
        title: 'Event Assistant',
        src: require("../assets/team/Danielle-Headshot.png")
      },
      {
        name: 'Becca Garcia',
        title: 'Event Assistant',
        src: require("../assets/team/Becca-Headshot.png")
      }
    ],
  }),
}
</script>
