<template>
  <div class="team">
    <PozzyTeam/>
  </div>
</template>

<script>
// @ is an alias to /src
import PozzyTeam from '@/components/Team.vue'

export default {
  name: 'Team',
  components: {
    PozzyTeam
  }
}
</script>
